.radiobox {
    display: block;
    position: relative;
    padding-left: 2.25rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'SF Pro Display', sans-serif;
    letter-spacing: .5px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    input:checked ~ .radiobox-checkmark:after {
        display: block;
    }

    input:checked ~ .radiobox-checkmark {
        border: 1px solid $theme-color;
    }
    

    &:hover input ~ .radiobox-checkmark {
        border: 1px solid $theme-color;
    }

    &:checked ~ .radiobox-checkmark {
        border: 1px solid $theme-color;
    }

    .radiobox-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid $light-grey-color;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
           top: 3px;
           left: 3px;
           width: 8px;
           height: 8px;
           border-radius: 50%;
           background: $theme-color;
       }
      }

  }
  