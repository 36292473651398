.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &~.checkbox-checkmark {
            background-color: transparent;
        }

        &:disabled:checked~.checkbox-checkmark {
            background-color: $grey-color  !important;
            border: 1px solid $grey-color  !important;
        }

        &:checked~.checkbox-checkmark {
            background-color: $black-color;
            border: 1px solid $black-color;
        }

        &:checked~.checkbox-checkmark:after {
            display: block;
        }
    }

    &-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.125rem;
        width: 1.125rem;
        background-color: transparent;
        border: 1px solid $light-grey-color;
        border-radius: 3px;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 5px;
            top: 1px;
            width: 6px;
            height: 12px;
            border: solid $white-color;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.checkbox-label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.125rem;
}