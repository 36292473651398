.pagination {
    .page-item {
        .page-link {
            color: $black-color;
        }
        &.active {
            .page-link {
                background: $theme-color;
                border: 1px solid $theme-color;
                color: $white-color;
            }
        }
    }
}