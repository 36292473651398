.table {
    thead {
        tr {
            th {
                background: $light-bg;
                font-size: .85rem;
            }
        }
    }
}

.table>:not(:first-child) {
    border-top: none;
}