@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'SF Pro Display';
    src: url('../../../fonts/SFProDisplay-Regular.eot');
    src: url('../../../fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../../../fonts/SFProDisplay-Regular.woff') format('woff'),
        url('../../../fonts/SFProDisplay-Regular.ttf') format('truetype'),
        url('../../../fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../../../fonts/SFProDisplay-Medium.eot');
    src: url('../../../fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('../../../fonts/SFProDisplay-Medium.woff') format('woff'),
        url('../../../fonts/SFProDisplay-Medium.ttf') format('truetype'),
        url('../../../fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../../../fonts/SFProDisplay-Bold.eot');
    src: url('../../../fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('../../../fonts/SFProDisplay-Bold.woff') format('woff'),
        url('../../../fonts/SFProDisplay-Bold.ttf') format('truetype'),
        url('../../../fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}