.modal {
    .modal-header {
        border: none;

        .btn-close {
            width: 1.5rem;
            height: 1.5rem;
            background-size: 1.5rem;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }

    .modal-footer {
        &.content-start {
            display: flex;
            justify-content: space-between !important;
        }
    }
}