.breadcrumb {
    margin-bottom: 0;
    font-family: 'SF Pro Display', sans-serif;

    a {
        color: $theme-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &-item {
        font-size: 1.25rem;
    }
}