.status-card {
    width: 8rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    padding: .75rem;
    box-shadow: 0 0 6px rgba(0,0,0,.12);
    text-align: center;

    &--info {
        margin: 0;
        padding: 1rem 0 .5rem;
        font-size: 0.875rem;
        line-height: 1;
        font-family: 'SF Pro Display', sans-serif;
    }

    &--date {
        display: block;
        font-size: 0.5rem;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem 0 0;
    }

}