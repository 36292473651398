h2 {
    font-family: 'SF Pro Display', sans-serif;
}

.logo-font {
    font-family: 'Courier Prime', monospace;
    font-size: 24px;
    color: white;
    margin: 0;
    padding: 0;
}

.form-fieldset-title {
    display: block;
    font-size: 1.425rem;
    font-weight: 700;
    line-height: 1.5;

    @media screen and (max-width: 767px) {
        font-size: 1.25rem;
    }
}

.simple-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: .18px;
}

h4 {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 400;
    letter-spacing: .18px;
}

.info-text {
    color: $grey-color;
    line-height: 2;

    @media screen and (max-width: 767px) {
        line-height: 1.5;
    }
}

.text-sm {
    font-size: .8rem;
}

//colors
.text-theme {
    color: $theme-color;
}

.text-green {
    color: green;
}

.text-grey {
    color: $grey-color;
}

.text-red {
    color: rgb(198, 58, 58);
}

.text-bold {
    font-weight: 600;
}

.line-height-1 {
    line-height: 1.1;
}

.text-right {
    text-align: right;
}

.text-lg {
    font-size: 1.25rem;
}

.text-normal {
    font-size: 0.875rem;
}

.active {
    color: $theme-success;
}

.inactive {
    color: $grey-color;
}