.navbar-light {
    .navbar-nav {
        .nav-link {
            display: flex;
            align-items: center;
            padding: 0.75rem;
            color: $black-color;
            font-family: 'SF Pro Display', sans-serif;
            font-weight: 500;
            letter-spacing: .15px;

            &--content {
                display: inline-block;
                padding-left: 1.25rem;
            }

            &:hover {
                color: $theme-secondary-color;
            }

            &.active {
                color: $theme-secondary-color;
            }
        }
    }
}

.badge {
    &.white {
        background: white;
        color: $theme-color
    }

    background: $theme-color;
    padding: .25rem .35rem;
    border-radius: 25px;
    font-size: .65rem;
}

.notifications {
    position: relative;

    .badge {
        position: absolute;
        right: -75%;
        top: -25%;
    }
}

.project-notifications {
    position: relative;

    .badge {
        position: absolute;
        right: -30px;
        top: -25%;
    }
}