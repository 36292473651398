.form-control {
    padding: .7rem 1.1rem;
    border-radius: 0.25rem;
    transition: none;
    border: 1px solid $light-grey-color;

    &:hover {
        border: 1px solid $black-color;
    }

    &:hover~.input-group-text {
        border: 1px solid $black-color;
        border-left: none;
    }

    &:focus {
        outline: none;
        border-color: $theme-color;
        box-shadow: none;
    }

    &:focus~.input-group-text {
        border-color: $theme-color;
        border-left: none;
    }

    &~.input-group-text {
        color: $theme-secondary-color;
        margin-left: -3px !important;
        background: $white-color;
        border-left: none;
        z-index: 3;
    }
}

.addon-left {
    >.input-group-text {
        position: absolute;
        left: .25rem;
        top: 50%;
        transform: translateY(-50%);
        color: $black-color;
        background: $white-color;
        border-right: none;
        z-index: 4;
        transition: none;
        border: none;
    }

    .form-control {
        border-radius: 3px !important;
        padding: 16px 50px 16px 55px;
    }
}

.form-label {
    display: inline-block;
    font-size: 0.75rem;
    font-family: 'SF Pro Display', sans-serif;
    color: $grey-color;
}

.custom-form-label {
    display: inline-block;
    padding: 0 0.375rem;
    position: absolute;
    top: -0.5rem;
    left: 0.75rem;
    z-index: 4;
    background: $white-color;
    font-size: 0.75rem;
    font-family: 'SF Pro Display', sans-serif;
    color: $grey-color;
}

.custom-form-label-required {
    display: inline-block;
    padding: 0 0.375rem;
    position: absolute;
    top: -0.7rem;
    left: 0.75rem;
    z-index: 4;
    background: $white-color;
    font-size: 0.75rem;
    font-family: 'SF Pro Display', sans-serif;
    color: $grey-color;
}

.eye-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .8rem;
    z-index: 10;
    color: $theme-secondary-color;
}

// hide the arrow if type is number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.react-datepicker-popper {
    z-index: 100 !important;
}