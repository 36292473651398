html {
    height: 100%;
}

body {
    color: $black-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
}

.dashboard-layout {
    height: 100vh;   
    overflow: hidden;  
    margin: 0px;  /* removes default style */
    display: flex;  /* enables flex content for its children */
    box-sizing: border-box;
    flex-flow: column;
}

.main {
    height: 100%;  /* allows both columns to span the full height of the browser window */
    display: flex;
}

.pointer {
    cursor: pointer;
}

.height-100 {
    height: 100%;
}

.bg-success {
    background: $theme-secondary-color!important;
}

.color-indicator {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: .75rem;
}

.border-right {
    border-right: 1px solid $light-grey-color;
}

.no-data-found-img {
    max-width: 16rem;
}

.user-avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 1.5rem;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    border: 1px solid $light-grey-color;
    font-size: 3rem;
}