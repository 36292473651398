.dropdown-toggle {
    &:after {
        content: normal;
    }

    &--icon {
        display: inline-block;
        transition: .3s;
    }

}

.dropdown-toggle.primary {
    color: $black-color;

    &:hover {
        color: $theme-secondary-color;
    }
}

.show {
    .dropdown-toggle.primary {
        color: $theme-secondary-color;
    }

    .dropdown-toggle--icon {
        transform: rotate(180deg);
    }
}

.dropdown-item {
    &:hover {
        background: $theme-secondary-hover;
    }
}