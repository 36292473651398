$white-color: #fff;
$black-color: #000;
$grey-color: #7B7B7B;
$light-grey-color: #DADADA;
$light-bg: #FAFAFA;

$theme-color: #019EB2;
$theme-secondary-color: #D1337F;
$theme-secondary-hover: rgba(208,52,126,0.04);
$theme-secondary-focus: rgba(208,52,126,0.12);

$theme-primary-hover: rgba(224,247,250,1);

$theme-success: rgb(30, 208, 116);