.form-select {
    padding: .7rem 1.1rem;
    border-radius: 0.25rem;
    border: 1px solid $light-grey-color;

    &:hover {
        border: 1px solid $black-color;
    }

    &:focus {
        outline: none;
        border-color: $theme-color;
        box-shadow: none;
    }

}