.nav-tabs {
    border: none;
    background: $theme-color;
    box-shadow: 0 7px 10px rgba(0,0,0,.12);
    &.custom{
        color:black;
        background:$white-color;
        .nav-link {
            color:black;
             &.active {
            color:$theme-color;
            background: transparent;
            &:after {
                background:$theme-color ;
            }
        }}
    }
    .nav-link {
        padding: 1rem 1.5rem;
        text-transform: uppercase;
        font-family: 'SF Pro Display', sans-serif;
        letter-spacing: 1.25px;
        font-size: .85rem;
        color: $white-color;
        border-radius: 0;
        border: none;
        position: relative;

        &.active {
            color: $white-color;
            background: transparent;
            border-radius: 0;
            border: none;

            &:after {
                content: "";
                background: $white-color;
                height: 3px;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &:hover {
            &:after {
                content: "";
                background: $white-color;
                height: 3px;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}