.input-search {
    position: relative;


    &--icon {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .form-control {
        padding-left: 3rem;
        border-radius: 50px;
    }
}