.btn-link {
    display: inline-block;
    padding: 1rem .5rem;
    color: $theme-secondary-color;
    font-weight: bold;
    font-size: 0.875rem;
    text-decoration: none;
    outline: none;

    &:hover {
        color: $theme-color;
    }

    &--primary {
        color: $theme-color;
        font-weight: 500;

        &:hover {
            text-decoration: underline;
            color: $theme-secondary-color;
        }
    }


    &.dropdown-toggle {
        color: $white-color;
        font-weight: 500;
    }
}

.disabled {
    color: $light-grey-color  !important;
}

.btn {
    padding: .7rem 1rem;
    text-transform: capitalize;
    font-family: 'SF Pro Display', sans-serif;
    letter-spacing: 1.25px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &[disabled] {
        background: $grey-color;
    }

    &-sm {
        font-size: .75rem;
        padding: .5rem .5rem;
    }
}

.btn-primary {
    background: $theme-color;
    border-color: $theme-color;


    &:hover,
    &:active {
        border-color: $theme-color;
        background: darken($theme-color, 2%);
    }

    &:focus {
        border-color: $theme-color;
        background: darken($theme-color, 2%);
        box-shadow: 0 4px 8px rgba(0, 0, 0, .12);
    }
}

.btn-outline-primary {
    border: 1px solid $theme-color;
    color: $theme-color;

    &:hover {
        background: $theme-secondary-hover;
        color: $theme-color;
        border: 1px solid $theme-color;
        ;
    }

    &:focus {
        border: 1px solid $theme-color;
        ;
        background: $theme-secondary-focus;
        color: $theme-color;
    }
}

.btn-outline-secondary {
    border: 1px solid $light-grey-color;
    color: $grey-color;
    box-shadow: none !important;
    outline: none !important;

    &:hover {
        background: $theme-primary-hover;
        color: $theme-color;
        border: 1px solid $light-grey-color;
    }

    &:focus,
    &:active {
        border: 1px solid $light-grey-color;
        background: $theme-primary-hover;
        color: $theme-color;
    }

    &.active {
        border: 1px solid $light-grey-color;
        background: $theme-primary-hover;
        color: $theme-color;
    }
}

.btn--dark {
    display: inline-flex;
    align-items: center;
    color: $black-color;

    &:hover {
        color: $theme-color;
    }
}